.registros-container {
  background: #b2bec3;
  min-height: calc(100vh - 66px);
}

.registros-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #dfe6e9;
  box-shadow: 12px -2px 10px 0px black;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.registros-header-sides-left {
  display: flex;
  align-items: center;
}

.registros-header-logo {
  height: 50px;
  padding: 10px 10px 10px 20px;
}

.registros-header-text {
  font-family: sans-serif;
  font-size: 1.8vw;
}

.registros-header-sides-right {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.registros-header-searchBarOpen {
  display: flex;
  align-items: center;
}

.registros-header-stdInput {
  height: 1.5vw;
  background: #eaeaea;
  border-radius: 8px;
  padding-left: 10px;
  font-size: 0.9vw;
  width: 80%;
}

.registros-header-icon {
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-size: 1.5vw;
}

.registros-body {
  text-align: center;
  padding: 20px 40px;
}

.registros-nomeHotel {
  font-size: xx-large;
  color: #b61615;
  font-family: sans-serif;
}

.registros-label {
  margin: 0 15px;
  font-size: 1.5vw;
  font-family: sans-serif;
}

.registros-sublabel {
  margin: 0 15px;
  font-size: 1vw;
  color: darkslategray;
  font-family: sans-serif;
}

.registros-input-label {
  display: flex;
  flex-direction: column;
}

.registros-forms {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  margin-top: 10px;
}

.registros-input {
  display: flex;
  flex-direction: column;
  text-align: initial;
}

.registros-stdInput {
  height: 3vw;
  background: #eaeaeacc;
  margin: 15px;
  border-radius: 8px;
  padding-left: 10px;
  font-size: 1vw;
  width: 80%;
}

.registros-stdInputbtn {
  width: 30%;
  border-radius: 8px;
  color: #b61615;
  font-size: 1.3vw;
  font-weight: bold;
  padding: 10px;
  background: #c9c9c9;
  cursor: pointer;
  font-family: sans-serif;
  text-decoration: none;
  justify-self: self-end;
  align-self: flex-end;
  grid-column: 2;
  grid-row: 3;
}

.registros-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #d3d3d3bd;
  display: grid;
  justify-content: center;
  align-content: center;
}

.registros-modal-card {
  background: gray;
  width: 500px;
  height: 300px;
  border-radius: 8px;
  display: grid;
  justify-content: center;
  box-shadow: 8px 8px 20px 0px #7e7e7e;
}

.registros-modal-imagem {
  justify-self: center;
  background: #b0ffb0;
  border-radius: 100%;
  padding: 5px;
  width: 20%;
  margin: 20px 0 0 0;
  box-shadow: 3px 6px 20px 0px #a8ffa8;
}

.registros-modal-titulo {
  font-size: 25px;
  font-family: sans-serif;
  font-weight: bold;
  color: white;
  justify-self: center;
  padding: 0 30px;
  text-align: center;
}

.registros-modal-footer {
  text-align: center;
  align-self: center;
}

.registros-modal-confirmbtn {
  width: 50%;
  border-radius: 8px;
  margin: 15px 65px 20px;
  color: #b61615;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
  background: #c9c9c9;
  cursor: pointer;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  justify-self: self-end;
}

.registros-modal-texto {
  font-size: 18px;
  font-family: sans-serif;
  color: white;
  justify-self: center;
}

.registros-modal-imagem-warning {
  justify-self: center;
  padding: 5px;
  width: 20%;
  margin: 20px 0 0 0;
}

.registros-file-container {
  padding: 25px 0;
}

.registros-file-dropzone {
  cursor: pointer;
  align-self: center;
  width: 100%;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.registros-file-dropzone2 {
  cursor: pointer;
  align-self: center;
  text-align: left;
  background: white;
  display: flex;
  height: 300px;
  text-align-last: center;
  border-radius: 15px;
  border: 1px;
  border-color: black;
  border-style: dashed;
  align-items: center;
  justify-content: center;
}

.registros-file-input {
  outline: none;
  font-size: 1.5vw;
}

.registros-file-input2 {
  outline: none;
  font-size: 1.5vw;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.registros-file-labels {
  margin: 5px 0;
  list-style: none;
  padding: 0;
}

.registros-file-btn {
  margin-bottom: 7px;
}

.registros-data-input {
  height: 3vw;
  margin: 15px;
  border-radius: 8px;
  padding-left: 10px;
  font-size: 1.3vw;
  width: 30%;
  text-align: center;
}

.registros-subtituloHotel {
  padding: 5px 0 10px;
  font-size: 1vw;
  color: darkslategray;
  font-family: sans-serif;
}

.fullInputTable {
  width: 100%;
  border: none;
  background: none;
}

.fullInputTable::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: blue;
  opacity: 1; /* Firefox */
}

.fullInputGroupTable {
  margin-bottom: 0 !important;
}

body {
  @media (max-width: 720px) {
    overflow-x: hidden;
  }
}

.analytics-container {
  background: #b2bec3;
  min-height: calc(100vh - 66px);
}

.analytics-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #dfe6e9;
  box-shadow: 12px -2px 10px 0px black;
  width: 100%;
}

.analytics-header-sides-left {
  display: flex;
  align-items: center;
}

.analytics-header-logo {
  height: 50px;
  padding: 10px 10px 10px 20px;
}

.fab {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 100%;
}

.analytics-header-text {
  font-family: sans-serif;
  font-size: 1.8vw;
}

.analytics-header-sides-right {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.analytics-header-searchBarOpen {
  display: flex;
  align-items: center;
}

.analytics-header-stdInput {
  height: 1.5vw;
  background: #eaeaea;
  border-radius: 8px;
  padding-left: 10px;
  font-size: 0.9vw;
  width: 80%;
}

.analytics-header-icon {
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-size: 1.5vw;
}

.analytics-body {
  text-align: center;
  padding: 10px 40px 0;
  height: 100%;
}

.analytics-graph {
  background-color: white;
  width: 100%;
}

.analytics-table-title {
  font-size: large;
  font-family: sans-serif;
  border: 1px solid black;
  padding: 10px;
}

.analytics-table {
  width: 100%;
  padding: 10px;
  /* border: 1px solid black; */
  background: white;
  box-shadow: 5px 7px 8px grey;
}

.analytics-table-line {
  font-size: 25px;
  font-family: sans-serif;
  width: 15%;
  border: 1px solid black;
}

.analytics-table-subtitle {
  font-family: sans-serif;
  /* border: 1px solid black; */
  padding: 10px;
  background: #119e91;
  color: white;
  font-size: small;
  text-align: center;
}

.analytics-table-subline {
  padding: 10px;
  border: 1px solid black;
  padding: 10px;
  font-size: small;
  text-align: right;
}

.analytics-select {
  font-size: 20px;
  border-radius: 8px 8px 0 0;
  padding: 5px;
  outline: none;
  margin: 0 7px;
  border: 1px solid gray;
  box-shadow: 5px 7px 8px gray;
}

.analytics-consulta {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.analytics-data {
  font-size: 20px;
  border-radius: 8px 8px 0 0;
  margin: 0 7px;
  border: 1px solid gray;
  padding: 5px;
  box-shadow: 5px 7px 8px gray;
}

.analytics-btn {
  border-radius: 8px;
  color: #b61615;
  font-size: 15px;
  font-weight: bold;
  margin: 0 7px;
  padding: 10px;
  background: #c9c9c9;
  cursor: pointer;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  box-shadow: 5px 7px 8px gray;
  border: 1px solid gray;
  width: 10%;
  height: fit-content;
  align-self: center;
}

.analytics-stats-green {
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analytics-stats-black {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analytics-up-icon {
  color: green;
  font-size: 30px;
}

.analytics-stats-red {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analytics-down-icon {
  color: red;
  font-size: 30px;
}

.analytics-table-line-dia {
  font-size: 25px;
  font-family: sans-serif;
  width: 15%;
  border: 1px solid black;
  background: #8395a7;
  color: white;
  padding: 0 10px;
}

.analytics-table-line-mes {
  font-size: 25px;
  font-family: sans-serif;
  width: 15%;
  border: 1px solid black;
  background: #576574;
  color: white;
  padding: 0 10px;
}

.analytics-table-line-ano {
  font-size: 25px;
  font-family: sans-serif;
  width: 15%;
  border: 1px solid black;
  border-radius: 8px;
  background: #222f3e;
  color: white;
  padding: 0 10px;
}

._3vt7_Mh4hRCFbp__dFqBCI {
  background-color: white;
  border: 1px solid gray;
  border-radius: 8px;
  box-shadow: 5px 7px 8px grey;
  color: black;
  margin: 0 0 0 7px;
}

._2iA8p44d0WZ-WqRBGcAuEV {
  border: none;
}

.analytics-resumo {
  display: grid;
  grid-template-columns: 1fr 20px 1fr 20px 1fr 1fr 1fr 1fr 1fr;
  margin: 30px 0;
  grid-gap: 15px;
}

.analytics-resumo-card {
  background: green;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: sans-serif;
  font-size: 20px;
  box-shadow: 5px 7px 8px grey;
}

.analytics-resumo-card-title {
  margin: 5px;
}

.analytics-resumo-card-value {
  margin: 10px;
}

.analytics-resumo-sign {
  display: flex;
  align-items: center;
  justify-self: center;
  font-size: 30px;
  font-weight: bold;
}

.analytics-resumo-card-porcentagem {
  display: flex;
  justify-content: flex-end;
}

.analytics-resumo-card-porcentagem-value {
  background: #000000e8;
  padding: 5px 20px;
  border-radius: 8px 0 8px 0;
  box-shadow: 5px 7px 8px grey;
}

._7ahQImyV4dj0EpcNOjnwA,
._3vt7_Mh4hRCFbp__dFqBCI input {
  font-size: 20px;
}

.analytics-consulta-unidades {
  width: 40%;
  display: flex;
  text-align: left;
  align-items: center;
  font-size: 20px;
  font-family: sans-serif;
}

.analytics-consulta-datas {
  display: flex;
  text-align: left;
  margin: 0 15px 0 10px;
  align-items: center;
  font-size: 20px;
  font-family: sans-serif;
}

.analytics-switch-div {
  display: flex;
  text-align: left;
  align-items: center;
  font-size: 20px;
  font-family: sans-serif;
  margin: 0 0 0 10px;
  gap: 5px;
}

.analytics-switch {
  display: flex;
  font-size: 15px;
  font-family: sans-serif;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.analytics-graficos-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

.analytics-graficos-grafico {
  width: 100%;
  background-color: white;
  box-shadow: 5px 7px 8px grey;
}

.analytics-graficos-grafico-titulo {
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  padding: 10px;
}

.loading-bg {
  background-color: #000000c2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: fadein 1s;
  z-index: 10000;
}

.loading-text {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: white;
  font-size: 5vh;
  font-family: sans-serif;
  animation: go-back 2s infinite alternate;
  z-index: 10000;
}

.lodaing-logo {
  margin: 0 15px;
  z-index: 10000;
}

.analytics-preSelect-div {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: dimgray;
  z-index: 10000;
}

.analytics-preSelect-tela {
  height: calc(100% - 4vw);
  color: white;
}

.analytics-preSelect-titulo {
  height: calc(50% - 4vw);
  font-size: 30px;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

._3vLmCG3bB3CM2hhAiQX1tN {
  position: absolute;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  z-index: 10000000;
}

.analytics-header-unidades {
  position: sticky;
  top: 10px;
  background: #dfe6e9;
  border-radius: 8px;
  font-size: 25px;
  padding: 10px;
  box-shadow: 5px 4px 10px 3px grey;
  z-index: 10000;
  color: black;
  font-family: sans-serif;
}

@keyframes go-back {
  from {
    transform: translateX(100px);
  }

  to {
    transform: translateX(-100px);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.list-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.list-cards-graficos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

@media (max-width: 1000px) {
  .list-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
}

@media (max-width: 700px) {
  .list-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}

/* Bootstrap */

.navbar-brand {
  display: contents;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

/* hoover de tabelas */
.table-hover tbody tr:hover {
  color: #ffffff !important;
  background-color: rgb(17, 158, 144) !important;
}

.hidden {
  display: none;
}

.shown {
  font-size: small;
  cursor: default;
}

.analytics-dre {
  text-align: start;
}

.dataStudio {
  width: 100%;
  height: 100%;
}

.divdatastudio {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 56px;
  top: 0;
}

.rodapeCard {
  font-size: small;
  text-align: end;
}

.addRegistros {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background: #b71918;
  color: white;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  text-align: center;
  box-shadow: 1px 3px 13px #690000;
  cursor: pointer;
}

.AddUserBack {
  width: 100%;
  position: absolute;
  z-index: 1200;
  height: 100%;
  background: #212121db;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.badge-danger {
  top: 5px;
  right: -5px;
  position: absolute;
}

.rowSelector {
  align-items: center;
  padding-bottom: 10px;
}

/* MaterialIcons */
.MuiListItem-root {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  justify-content: flex-start;
  text-decoration: none;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 30px;
}

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (max-width: 600px) {
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100% !important;
  height: 100% !important;
  background-color: #000;
}

.firulaButton {
  height: 3rem;
  width: 10rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #111827;
  cursor: pointer;
}

.firulaButton::after {
  content: '';
  height: calc(100% + 10px);
  width: calc(100% + 10px);
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: -1;
  border-radius: 10px;
  background: linear-gradient(90deg, deeppink, deepskyblue, lawngreen, deeppink);
  background-size: 300% 100%;
  animation: animate 3s ease infinite;
}

@keyframes animate {
  to {
    background-position: 0% 0%;
  }

  50% {
    background-position: 90% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.valorTaxa {
  font-size: small;
  text-align: right;
  font-weight: bold;
}

.valorTaxaVertical {
  font-size: small;
  text-align: right;
  font-weight: bold;
  opacity: 1 !important;
}

.icon {
  font-size: x-large;
}

.ColVar {
  text-align: center;
}

.selectedVertical {
  background: blue !important;
  color: white !important;
  opacity: 1 !important;
}

.myOwnBadge {
  right: -5px;
}

.usuariosPicker {
  position: static;
}

.perguntaMenu {
  position: inherit !important;
}

#dropdown-button-drop-Colab {
  box-shadow: none;
  font-weight: 600;
  border: none;
  padding: 3px;
  margin: 0;
  font-size: small;
  margin-left: 5px;
}

.stickyCollumns {
  position: sticky;
  left: 52px;
  background: white;
  /* border-right: 1px solid #868787; */
  background: inherit;
  color: inherit;
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 2;
}

.show {
  display: table-cell;
}

.hidden {
  display: none;
}

.drawerActive {
  filter: drop-shadow(4px 4px 2px #119e91);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(17, 158, 145);
  border-radius: 10px;
  border: 1px solid lightgray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(2, 118, 106);
}

.valorTaxa {
  font-size: small;
  text-align: right;
  font-weight: bold;
}

.valorTaxaVertical {
  font-size: small;
  text-align: right;
  font-weight: bold;
  opacity: 1 !important;
}

.icon {
  font-size: x-large;
}

.selectedVertical {
  background: blue !important;
  color: white !important;
  opacity: 1 !important;
}

.myOwnBadge {
  right: -5px;
}

.usuariosPicker {
  position: static;
}

.perguntaMenu {
  position: inherit !important;
}

#dropdown-button-drop-Colab {
  box-shadow: none;
  font-weight: 600;
  border: none;
  padding: 3px;
  margin: 0;
  font-size: small;
  margin-left: 5px;
}

.stickyHeader {
  position: sticky;
  left: 0;
  top: auto;
  width: 100vw;
  margin: 0;
  /* z-index: 10000; */
}

.modal-backdrop.show {
  z-index: 10000;
}

#standard-Nome-label {
  z-index: 0 !important;
}

input {
  outline: none;
}

.login-link {
  outline: none;
  border: none;
  box-shadow: none !important;
}

.login-card-footer {
  display: flex;
  justify-content: space-between;
}

.login-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-image: url('./assets/rioDeJaneiroFreepik1.jpg');
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.bgimg {
  width: 100%;
}

.bupicon {
  position: absolute;
  top: 5vh;
  left: 7vw;
  z-index: 2;
  width: 5vw;
}

.centerCell {
  vertical-align: middle;
  text-align: -webkit-center;
}

.login-card {
  background-color: #494949e3;
  border-top-left-radius: 15%;
  border-bottom-right-radius: 15%;
}

.titulo {
  text-align: center;
  font-size: xx-large;
  font-family: sans-serif;
}

.subtitulo {
  text-align: center;
  font-size: smaller;
  font-family: sans-serif;
}

.formEntrar {
  display: flex;
  flex-direction: column;
  margin: 25px 40px 0;
  align-items: center;
}

.stdInput {
  height: 35px;
  background: #eaeaeacc;
  margin: 15px;
  border-radius: 8px;
  padding-left: 10px;
  font-size: 15px;
  width: 80%;
}

.stdInputbtn {
  width: 50%;
  border-radius: 8px;
  margin: 15px 25px 20px;
  color: #b61615;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
  background: #c9c9c9;
  cursor: pointer;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
}

.stdInputbtn:hover,
.stdInputbtn:active {
  background: #a7a7a7;
}

.login-esqueciSenha-div {
  margin: 10px 0 15px 15px;
}

.esqueciSenha {
  background: none;
  border: none;
  color: #a494ff;
  text-decoration: underline;
}

.esqueciSenha:active,
.esqueciSenha:hover {
  cursor: pointer;
  color: #7c65ff;
}

.modal {
  z-index: 10000 !important;
}

.systemVersion {
  position: absolute;
  bottom: 25px;
  right: 35px;
  font-size: small;
}

.MuiSpeedDialAction-staticTooltipLabel{
  font-size: small !important;
  width: max-content !important;
}